<template>
  <div class="seo-component seo-hidden container">
    <h1>{{ seoTitle }}  | IME</h1>
    <p class="lead">{{ seoDescription }}</p>
    <p>{{ seoTags }}</p>
    <p>{{ path.base_url +'/'+ seoCanonical }}</p>
  </div>
</template>

<script>

export default {
  name: 'SeoComponent',

  props: {
    seoTitle: String,
    seoDescription: String,
    seoTags: String,
    seoCanonical: String,
    seoImage: String
  },

  data() {
    return {
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    }
  },

  metaInfo () {
    return {
      title: this.seoTitle,
      //base: { target: '_blank', href: '/' },
      htmlAttrs: {
        lang: 'es-mx',
      },
      meta: [
        { name: 'description', content: this.seoDescription },
        { name: 'keywords', content: this.seoTags },
        { name: 'robots', content: "index,follow" },
        { name: 'revisit-after', content: "3 days" },
        { 'http-equiv': 'expires', content: "43200" },
        { 'http-equiv': 'language', content: "es-mx" },
        { property: 'og:title', content: this.seoTitle, template: chunk => `${chunk} | IME` },
        { property: 'og:type', content: 'article' },
        { property: 'og:description', content: this.seoDescription },
        { property: 'og:url', content: this.path.base_url +'/'+ this.seoCanonical },
        { property: 'og:image', content: this.seoImage },
        { name: 'twitter:title', content: this.seoTitle, template: chunk => `${chunk} | IME` },
        { name: 'twitter:card', content: this.seoDescription },
        { name: 'twitter:url', content: this.path.base_url +'/'+ this.seoCanonical },
        { name: 'twitter:image', content: this.seoImage },
        { name: 'msapplication-TileColor', content: '#691c32' },
        { name: 'theme-color', content: '#235b4e' },
        { name: 'msapplication-TileImage', content: this.path.base_url + '/favicons/ms-icon-144x144.png' },
      ],
      link: [
        { rel: 'canonical', href: this.path.base_url +'/'+ this.seoCanonical },
        { rel: 'apple-touch-icon', sizes: '57x57', href: this.path.base_url + '/favicons/apple-icon-57x57.png' },
        { rel: 'apple-touch-icon', sizes: '60x60', href: this.path.base_url + '/favicons/apple-icon-60x60.png' },
        { rel: 'apple-touch-icon', sizes: '72x72', href: this.path.base_url + '/favicons/apple-icon-72x72.png' },
        { rel: 'apple-touch-icon', sizes: '76x76', href: this.path.base_url + '/favicons/apple-icon-76x76.png' },
        { rel: 'apple-touch-icon', sizes: '114x114', href: this.path.base_url + '/favicons/apple-icon-114x114.png' },
        { rel: 'apple-touch-icon', sizes: '120x120', href: this.path.base_url + '/favicons/apple-icon-120x120.png' },
        { rel: 'apple-touch-icon', sizes: '144x144', href: this.path.base_url + '/favicons/apple-icon-144x144.png' },
        { rel: 'apple-touch-icon', sizes: '152x152', href: this.path.base_url + '/favicons/apple-icon-152x152.png' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: this.path.base_url + '/favicons/apple-icon-180x180.png' },
        { rel: 'icon', type: 'image/png', sizes: '192x192', href: this.path.base_url + '/favicons/android-icon-192x192.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: this.path.base_url + '/favicons/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '96x96', href: this.path.base_url + '/favicons/favicon-96x96.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: this.path.base_url + '/favicons/favicon-16x16.png' },
        { rel: 'manifest', href: this.path.base_url + '/favicons/manifest.json' },
      ], 
    }
  },

  mounted() {
    this.track();
  },

  methods: {
    track () {
      this.$gtag.pageview({ page_title: this.seoTitle + ' | IME' });
      this.$gtag.pageview({ page_location: this.path.base_url +'/'+ this.seoCanonical });  
    }
  },

}
</script>
