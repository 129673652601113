<template>
  <div
    class="articles-category article-three-columns"
    :class="`${cfgCategory}`"
  >
    <SeoComponent
      :seoTitle="`${'Artículos | ' + this.cfg.nameCategory}`"
      :seoDescription="
        `${
          articulos.category_id.description
            ? articulos.category_id.description
            : 'Instituto de los Mexicanos en el Exterior'
        }`
      "
      :seoTags="`${'gobmx,sre,ime,' + this.cfg.category}`"
      :seoCanonical="`${this.cfg.category + '/articulos'}`"
      :seoImage="
        `${
          articulos.category_id.image_cover
            ? path.storage_files + articulos.category_id.image_cover.url
            : '/images/categorias/vaipoime.jpg'
        }`
      "
    />

    <b-container class="mt-5">
      <b-row class="mb-4" align-v="center">
        <b-col cols="12">
          <h1 class="display-4  super-strong">{{ cfgTitle }} : Artículos</h1>
          <hr class="line" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          col
          lg="4"
          md="4"
          sm="6"
          v-for="article in pageOfItems"
          :key="article.id"
          class="mb-4 "
        >
          <section class="">
            <div
              class="image-cover box-10 mb-3 z-depth-2"
              vertical-align="center"
            >
              <b-img
                v-bind:src="
                  `${
                    article.image_home_local
                      ? path.storage_files + article.image_home_local.url
                      : 'https://api-ime.k0s.ime.red/uploads/educacion_cat_67af6eddf0.jpg'
                  }`
                "
                fluid-grow
                :alt="`${article.title}`"
                class="zoom"
              ></b-img>
            </div>
            <div class="block mb-2">
              <b-badge
                class="red darken-1"
                v-b-tooltip.hover
                title="Video Blog"
                v-if="article.type_publication_id.id === 5"
              >
                <i class="fab fa-youtube"></i> </b-badge
              >&nbsp;
              <b-badge class="rojo">
                <b-link
                  :to="`/${article.category_id.slug}/`"
                  class="white-text"
                >
                  <i class="fas fa-tag"></i>&nbsp;
                  {{ article.category_id.name }}
                </b-link> </b-badge
              >&nbsp;
              <b-badge class="verde">
                <i class="fas fa-calendar-alt"></i>&nbsp;
                {{ article.published_at | moment("DD/MMM/YYYY") }}
              </b-badge>
            </div>
            <h4 class="mb-4">
              <b-link
                :to="
                  `/${article.category_id.slug}/articulo/${article.canonical}`
                "
              >
                {{ article.title }}
              </b-link>
            </h4>
          </section>
        </b-col>
      </b-row>

      <div class="mb-5 mt-3">
        <jw-pagination
          :items="articles"
          @changePage="onChangePage"
          :labels="customLabels"
          :pageSize="9"
        ></jw-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import SeoComponent from "@/components/Global/Seo.vue";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "Anterior",
  next: "Siguiente",
};

export default {
  name: "ArticlesThreeColumns",
  components: {
    SeoComponent,
  },

  props: {
    cfgTitle: String,
    cfgCategory: String,
  },

  data() {
    return {
      customLabels,
      pageOfItems: [],
      articles: [],
      articulos: {
        category_id: {},
      },
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  mounted() {
    document.title = this.cfgTitle + " | IME";
  },

  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint +
        "/blogs/?_limit=100&type_publication_id_in=1&type_publication_id_in=5&_sort=published_at:desc&post_type_in=3&post_type_in=4&post_type_in=5&post_type_in=6&category_id.slug=" +
        this.cfgCategory // <- Ajuste segun Categoria
    )
      .then((response) => response.json())
      .then((data) => {
        this.articles = data;
        this.articulos = data[0];
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */

  methods: {
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
  },
};
</script>
